import React from 'react';
import { MapContainer, TileLayer, LayersControl, LayerGroup } from 'react-leaflet'
import { Maker } from "./Marker";
import { RedMaker } from "./RedMarker";
import { YellowMaker } from "./YellowMaker";
import './Map.css';
import L from 'leaflet'
L.Icon.Default.imagePath = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/'

export const Map = () => {
  // 緯度軽度
  const position = [35.3607411, 138.727262];
  // 初期マップズームレベル
  const zoom = 6;

  const maps = [
    {position: [35.115707, 136.561386], contents: '三重県いなべ市'},
    {position: [34.855755, 136.451645], contents: '三重県亀山市'},
    {position: [34.768829, 136.129913], contents: '三重県伊賀市'},
    {position: [34.627663, 136.108292], contents: '三重県名張市'},
    {position: [34.965092, 136.624420], contents: '三重県四日市市'},
    {position: [34.328217, 136.829651], contents: '三重県志摩市'},
    {position: [34.577972, 136.527588], contents: '三重県松阪市'},
    {position: [35.062286, 136.683533], contents: '三重県桑名市'},
    {position: [34.718563, 136.505447], contents: '三重県津市'},
    {position: [34.490276, 136.630936], contents: '三重県玉城町'},
    {position: [35.020000, 136.507339], contents: '三重県菰野町'},
    {position: [34.881866, 136.584183], contents: '三重県鈴鹿市'},
    {position: [35.013474, 135.573517], contents: '京都府亀岡市'},
    {position: [35.624168, 135.060989], contents: '京都府京丹後市'},
    {position: [35.624168, 135.060989], contents: '京都府京丹波町'},
    {position: [35.011574, 135.768188], contents: '京都府京都市'},
    {position: [35.107391, 135.470245], contents: '京都府南丹市'},
    {position: [34.852959, 135.780075], contents: '京都府城陽市'},
    {position: [34.852665, 135.856857], contents: '京都府宇治田原町'},
    {position: [35.535576, 135.195572], contents: '京都府宮津市'},
    {position: [34.737179, 135.820053], contents: '京都府木津川市'},
    {position: [35.296726, 135.126526], contents: '京都府福知山市'},
    {position: [35.474796, 135.385986], contents: '京都府舞鶴市'},
    {position: [33.263542, 130.300842], contents: '佐賀県NPO支援'},
    {position: [33.263542, 130.300842], contents: '佐賀県みやき町'},
    {position: [33.450104, 129.967972], contents: '佐賀県唐津市'},
    {position: [33.288513, 130.110062], contents: '佐賀県多久市'},
    {position: [33.193752, 130.019150], contents: '佐賀県武雄市'},
    {position: [34.796921, 134.990189], contents: '兵庫県三木市'},
    {position: [34.889671, 135.225296], contents: '兵庫県三田市'},
    {position: [34.873585, 134.356094], contents: '兵庫県上郡町'},
    {position: [34.756618, 134.840897], contents: '兵庫県加古川市'},
    {position: [34.917370, 134.973343], contents: '兵庫県加東市'},
    {position: [34.927811, 134.841812], contents: '兵庫県加西市'},
    {position: [34.815495, 134.685455], contents: '兵庫県姫路市'},
    {position: [34.799816, 135.360092], contents: '兵庫県宝塚市'},
    {position: [34.853184, 134.931549], contents: '兵庫県小野市'},
    {position: [34.830132, 135.417221], contents: '兵庫県川西市'},
    {position: [35.339836, 134.853119], contents: '兵庫県朝来市'},
    {position: [34.439846, 134.914627], contents: '兵庫県淡路市'},
    {position: [34.689495, 135.195724], contents: '兵庫県神戸市'},
    {position: [34.689495, 135.195724], contents: '兵庫県神河町'},
    {position: [34.950317, 134.760239], contents: '兵庫県福崎町'},
    {position: [34.737690, 135.341827], contents: '兵庫県西宮市'},
    {position: [34.993427, 134.969284], contents: '兵庫県西脇市'},
    {position: [34.754974, 134.390350], contents: '兵庫県赤穂市'},
    {position: [41.895710, 140.694412], contents: '北海道七飯町'},
    {position: [43.195343, 140.783539], contents: '北海道余市町'},
    {position: [43.807823, 143.894379], contents: '北海道北見市'},
    {position: [42.795441, 140.934525], contents: '北海道喜茂別町'},
    {position: [44.178627, 142.400177], contents: '北海道士別市'},
    {position: [43.425354, 141.882812], contents: '北海道奈井江町'},
    {position: [43.196171, 141.775864], contents: '北海道岩見沢市'},
    {position: [43.061974, 141.354370], contents: '北海道幕別町'},
    {position: [43.223637, 141.516998], contents: '北海道当別町'},
    {position: [42.882591, 141.577805], contents: '北海道恵庭市'},
    {position: [43.225368, 141.649246], contents: '北海道新篠津村'},
    {position: [43.770798, 142.364792], contents: '北海道旭川市'},
    {position: [43.076241, 141.363663], contents: '北海道東川町'},
    {position: [43.056290, 141.784103], contents: '北海道栗山町'},
    {position: [43.806740, 141.933823], contents: '北海道沼田町'},
    {position: [42.412735, 141.106552], contents: '北海道登別市'},
    {position: [45.415676, 141.673080], contents: '北海道稚内市'},
    {position: [43.332951, 141.853989], contents: '北海道美唄市'},
    {position: [42.634094, 141.605499], contents: '北海道苫小牧市'},
    {position: [42.038567, 140.815903], contents: '北海道鹿部町'},
    {position: [35.253887, 140.385117], contents: '千葉県いすみ市'},
    {position: [35.372810, 140.368729], contents: '千葉県一宮町'},
    {position: [35.723442, 140.223969], contents: '千葉県佐倉市'},
    {position: [35.665859, 140.317917], contents: '千葉県八街市'},
    {position: [35.152210, 140.320862], contents: '千葉県勝浦市'},
    {position: [35.330425, 139.902557], contents: '千葉県君津市'},
    {position: [35.524231, 140.294266], contents: '千葉県大網白里市'},
    {position: [35.602993, 140.413528], contents: '千葉県山武市'},
    {position: [35.497898, 140.115601], contents: '千葉県市原市'},
    {position: [35.191483, 140.348785], contents: '千葉県御宿町'},
    {position: [35.776604, 140.318787], contents: '千葉県成田市'},
    {position: [35.720455, 140.646591], contents: '千葉県旭市'},
    {position: [35.375969, 139.916885], contents: '千葉県木更津市'},
    {position: [35.837151, 140.668793], contents: '千葉県東庄町'},
    {position: [35.787651, 139.903229], contents: '千葉県松戸市'},
    {position: [35.840866, 140.243942], contents: '千葉県栄町'},
    {position: [35.791504, 140.056366], contents: '千葉県白井市'},
    {position: [35.361092, 140.319275], contents: '千葉県睦沢町'},
    {position: [35.693066, 140.414261], contents: '千葉県芝山町'},
    {position: [35.428539, 140.288055], contents: '千葉県茂原市'},
    {position: [35.429947, 139.954346], contents: '千葉県袖ケ浦市'},
    {position: [35.955105, 139.874832], contents: '千葉県野田市'},
    {position: [35.734669, 140.826797], contents: '千葉県銚子市'},
    {position: [35.431152, 140.227066], contents: '千葉県長柄町'},
    {position: [35.412178, 140.354065], contents: '千葉県長生村'},
    {position: [35.114017, 140.098801], contents: '千葉県鴨川市'},
    {position: [33.818371, 135.218246], contents: '和歌山県印南町'},
    {position: [35.861515, 139.645508], contents: '埼玉県ときがわ町'},
    {position: [35.830132, 139.872253], contents: '埼玉県三郷市'},
    {position: [36.251614, 139.144867], contents: '埼玉県上里町'},
    {position: [35.999866, 139.623871], contents: '埼玉県伊奈町'},
    {position: [35.835770, 139.391052], contents: '埼玉県入間市'},
    {position: [36.039852, 139.453735], contents: '埼玉県吉見町'},
    {position: [35.957260, 139.402985], contents: '埼玉県坂戸市'},
    {position: [36.118347, 139.193008], contents: '埼玉県寄居町'},
    {position: [36.056656, 139.261795], contents: '埼玉県小川町'},
    {position: [36.056568, 139.320511], contents: '埼玉県嵐山町'},
    {position: [36.243328, 139.190628], contents: '埼玉県本庄市'},
    {position: [36.042164, 139.399948], contents: '埼玉県東松山市'},
    {position: [36.065990, 139.360916], contents: '埼玉県滑川町'},
    {position: [36.147362, 139.388657], contents: '埼玉県熊谷市'},
    {position: [35.852905, 139.412231], contents: '埼玉県狭山市'},
    {position: [36.177105, 139.181412], contents: '埼玉県美里町'},
    {position: [36.114788, 139.109726], contents: '埼玉県長瀞町'},
    {position: [35.855759, 139.327789], contents: '埼玉県飯能市'},
    {position: [35.981468, 139.334106], contents: '埼玉県鳩山町'},
    {position: [36.065758, 139.522171], contents: '埼玉県鴻巣市'},
    {position: [33.179996, 131.426788], contents: '大分県由布市'},
    {position: [34.787956, 135.679977], contents: '大阪府交野市'},
    {position: [34.626884, 135.600952], contents: '大阪府八尾市'},
    {position: [34.740063, 135.639725], contents: '大阪府四條畷市'},
    {position: [34.518658, 135.647736], contents: '大阪府太子町'},
    {position: [34.499676, 135.597275], contents: '大阪府富田林市'},
    {position: [34.883820, 135.663010], contents: '大阪府島本町'},
    {position: [34.814465, 135.650635], contents: '大阪府枚方市'},
    {position: [34.579304, 135.628647], contents: '大阪府柏原市'},
    {position: [34.821686, 135.428558], contents: '大阪府池田市'},
    {position: [34.491638, 135.629883], contents: '大阪府河南町'},
    {position: [34.366009, 135.273300], contents: '大阪府泉南市'},
    {position: [34.558002, 135.606232], contents: '大阪府羽曳野市'},
    {position: [34.005081, 135.955185], contents: '奈良県下北山村'},
    {position: [34.390530, 135.789795], contents: '奈良県大淀町'},
    {position: [34.685116, 135.804993], contents: '奈良県奈良市'},
    {position: [38.314362, 141.022034], contents: '宮城県塩竈市'},
    {position: [38.399918, 140.895493], contents: '宮城県富谷市'},
    {position: [37.962406, 140.877533], contents: '宮城県山元町'},
    {position: [38.177750, 140.643188], contents: '宮城県川崎町'},
    {position: [38.118587, 140.722397], contents: '宮城県村田町'},
    {position: [38.056599, 140.765793], contents: '宮城県柴田町'},
    {position: [31.907677, 131.420242], contents: '宮崎県'},
    {position: [31.990646, 131.323532], contents: '宮崎県国富町'},
    {position: [31.907677, 131.420242], contents: '宮崎県宮崎市'},
    {position: [31.601933, 131.378738], contents: '宮崎県日南市'},
    {position: [32.422947, 131.623962], contents: '宮崎県日向市'},
    {position: [31.928413, 131.007874], contents: '宮崎県高原町'},
    {position: [32.127991, 131.503342], contents: '宮崎県高鍋町'},
    {position: [36.695984, 137.213455], contents: '富山県富山市'},
    {position: [36.712223, 137.099564], contents: '富山県射水市'},
    {position: [36.675529, 136.868652], contents: '富山県小矢部市'},
    {position: [36.855976, 136.972870], contents: '富山県氷見市'},
    {position: [34.202408, 132.220398], contents: '山口県和木町'},
    {position: [34.178177, 131.473511], contents: '山口県山口市'},
    {position: [34.003090, 131.181793], contents: '山口県山陽小野田市'},
    {position: [34.166500, 132.218948], contents: '山口県岩国市'},
    {position: [34.166672, 131.205704], contents: '山口県美祢市'},
    {position: [34.370960, 131.182205], contents: '山口県長門市'},
    {position: [38.149563, 140.267838], contents: '山形県上山市'},
    {position: [38.593876, 140.372650], contents: '山形県大石田町'},
    {position: [38.255436, 140.339600], contents: '山形県山形市'},
    {position: [38.004482, 140.045792], contents: '山形県川西町'},
    {position: [38.764961, 140.301666], contents: '山形県新庄市'},
    {position: [38.483353, 140.380386], contents: '山形県村山市'},
    {position: [38.883438, 140.339371], contents: '山形県金山町'},
    {position: [35.662033, 138.568344], contents: '山梨県'},
    {position: [35.630302, 139.108749], contents: '山梨県上野原市'},
    {position: [35.776501, 138.423538], contents: '山梨県北杜市'},
    {position: [35.608360, 138.465012], contents: '山梨県南部町'},
    {position: [35.610474, 138.940048], contents: '山梨県大月市'},
    {position: [35.487499, 138.807861], contents: '山梨県富士川町'},
    {position: [35.487499, 138.807861], contents: '山梨県富士河口湖町'},
    {position: [35.460060, 138.847855], contents: '山梨県忍野村'},
    {position: [35.705158, 138.729263], contents: '山梨県甲州市'},
    {position: [35.660828, 138.515793], contents: '山梨県甲斐市'},
    {position: [35.647297, 138.639725], contents: '山梨県笛吹市'},
    {position: [35.551563, 138.905472], contents: '山梨県都留市'},
    {position: [35.708805, 138.446198], contents: '山梨県韮崎市'},
    {position: [35.481319, 138.706604], contents: '山梨県鳴沢村'},
    {position: [35.487492, 137.500610], contents: '岐阜県中津川市'},
    {position: [35.475986, 137.141495], contents: '岐阜県八百津町'},
    {position: [35.426132, 137.061081], contents: '岐阜県可児市'},
    {position: [35.332771, 137.132080], contents: '岐阜県多治見市'},
    {position: [35.470676, 136.627640], contents: '岐阜県大野町'},
    {position: [35.486549, 137.070602], contents: '岐阜県川辺町'},
    {position: [35.449322, 137.412811], contents: '岐阜県恵那市'},
    {position: [35.486946, 136.568207], contents: '岐阜県揖斐川町'},
    {position: [35.483051, 136.678772], contents: '岐阜県本巣市'},
    {position: [35.361740, 137.254639], contents: '岐阜県瑞浪市'},
    {position: [35.417351, 136.608521], contents: '岐阜県神戸町'},
    {position: [35.440243, 137.015671], contents: '岐阜県美濃加茂市'},
    {position: [35.544712, 136.907578], contents: '岐阜県美濃市'},
    {position: [35.495777, 136.917892], contents: '岐阜県関市'},
    {position: [34.597710, 133.463791], contents: '岡山県井原市'},
    {position: [34.584679, 133.772278], contents: '岡山県倉敷市'},
    {position: [34.655106, 133.919571], contents: '岡山県岡山市'},
    {position: [35.075592, 133.752762], contents: '岡山県真庭市'},
    {position: [34.672810, 133.746536], contents: '岡山県総社市'},
    {position: [34.755398, 134.018845], contents: '岡山県赤磐市'},
    {position: [34.791363, 133.616684], contents: '岡山県高梁市'},
    {position: [39.956512, 141.071121], contents: '岩手県八幡平市'},
    {position: [39.286816, 141.113159], contents: '岩手県北上市'},
    {position: [39.641457, 141.957092], contents: '岩手県宮古市'},
    {position: [40.326679, 141.460342], contents: '岩手県軽米町'},
    {position: [34.899254, 132.079880], contents: '島根県浜田市'},
    {position: [34.385254, 132.455338], contents: '広島県北広島町'},
    {position: [34.249252, 132.565811], contents: '広島県呉市'},
    {position: [34.666100, 132.703979], contents: '広島県安芸高田市'},
    {position: [34.857742, 133.017258], contents: '広島県庄原市'},
    {position: [34.341793, 132.907089], contents: '広島県竹原市'},
    {position: [34.121365, 134.404999], contents: '徳島県上板町'},
    {position: [34.070232, 134.554718], contents: '徳島県徳島市'},
    {position: [33.671768, 132.941071], contents: '愛媛県久万高原町'},
    {position: [33.757462, 132.703781], contents: '愛媛県伊予市'},
    {position: [33.839157, 132.765549], contents: '愛媛県松山市'},
    {position: [35.011612, 136.963867], contents: '愛知県大府市'},
    {position: [35.152802, 136.728210], contents: '愛知県愛西市'},
    {position: [34.850971, 136.914932], contents: '愛知県武豊町'},
    {position: [35.223621, 137.084122], contents: '愛知県瀬戸市'},
    {position: [35.378639, 136.944626], contents: '愛知県犬山市'},
    {position: [34.668972, 137.263824], contents: '愛知県田原市'},
    {position: [34.778843, 136.908249], contents: '愛知県美浜町'},
    {position: [34.862007, 137.061737], contents: '愛知県西尾市'},
    {position: [35.082344, 137.156219], contents: '愛知県豊田市'},
    {position: [35.184120, 137.048630], contents: '愛知県長久手市'},
    {position: [37.636768, 138.961700], contents: '新潟県三条市'},
    {position: [37.147873, 138.236038], contents: '新潟県上越市'},
    {position: [37.530716, 138.709366], contents: '新潟県出雲崎町'},
    {position: [37.025265, 138.253479], contents: '新潟県妙高市'},
    {position: [37.314350, 138.795090], contents: '新潟県小千谷市'},
    {position: [37.950882, 139.327896], contents: '新潟県新発田市'},
    {position: [37.371967, 138.558838], contents: '新潟県柏崎市'},
    {position: [37.698856, 139.057999], contents: '新潟県田上町'},
    {position: [37.039024, 137.862656], contents: '新潟県糸魚川市'},
    {position: [37.974525, 139.274368], contents: '新潟県聖籠町'},
    {position: [38.059708, 139.410355], contents: '新潟県胎内市'},
    {position: [37.446587, 138.851227], contents: '新潟県長岡市'},
    {position: [37.834511, 139.225998], contents: '新潟県阿賀町'},
    {position: [37.834511, 139.225998], contents: '新潟県阿賀野市'},
    {position: [37.230103, 138.961441], contents: '新潟県魚沼市'},
    {position: [35.728958, 139.294052], contents: '東京都あきる野市'},
    {position: [35.641411, 139.698120], contents: '東京都目黒区'},
    {position: [35.774944, 139.804581], contents: '東京都足立区'},
    {position: [36.685295, 139.966476], contents: '栃木県さくら市'},
    {position: [36.308601, 139.593140], contents: '栃木県佐野市'},
    {position: [36.543243, 140.102112], contents: '栃木県市貝町'},
    {position: [36.719860, 139.698196], contents: '栃木県日光市'},
    {position: [36.467354, 140.093384], contents: '栃木県益子町'},
    {position: [36.806732, 139.924225], contents: '栃木県矢板市'},
    {position: [36.532185, 140.187531], contents: '栃木県茂木町'},
    {position: [36.658501, 140.093918], contents: '栃木県那珂川町'},
    {position: [36.961689, 140.046051], contents: '栃木県那須塩原市'},
    {position: [37.019772, 140.121002], contents: '栃木県那須町'},
    {position: [36.630978, 139.986679], contents: '栃木県高根沢町'},
    {position: [36.567120, 139.745102], contents: '栃木県鹿沼市'},
    {position: [26.199503, 127.754791], contents: '沖縄県与那原町'},
    {position: [24.339764, 124.154434], contents: '沖縄県八重瀬町'},
    {position: [26.144402, 127.766922], contents: '沖縄県南城市'},
    {position: [26.481585, 127.975616], contents: '沖縄県宜野座村'},
    {position: [24.805490, 125.281158], contents: '沖縄県宮古島市'},
    {position: [26.456137, 127.926010], contents: '沖縄県金武町'},
    {position: [35.018356, 135.854660], contents: '滋賀県大津市'},
    {position: [35.058662, 135.994019], contents: '滋賀県守山市'},
    {position: [35.274464, 136.259628], contents: '滋賀県彦根市'},
    {position: [35.018017, 136.246017], contents: '滋賀県日野町'},
    {position: [35.021614, 135.997986], contents: '滋賀県栗東市'},
    {position: [35.004105, 136.084686], contents: '滋賀県湖南市'},
    {position: [34.966122, 136.167084], contents: '滋賀県甲賀市'},
    {position: [35.060749, 136.124435], contents: '滋賀県竜王町'},
    {position: [32.885998, 130.789719], contents: '熊本県合志市'},
    {position: [32.647816, 130.684280], contents: '熊本県宇城市'},
    {position: [33.016727, 130.691299], contents: '熊本県山鹿市'},
    {position: [32.235260, 130.797989], contents: '熊本県相良村'},
    {position: [32.979485, 130.813248], contents: '熊本県菊池市'},
    {position: [32.862514, 130.828659], contents: '熊本県菊陽町'},
    {position: [32.834721, 130.903030], contents: '熊本県西原村'},
    {position: [32.200989, 130.840897], contents: '熊本県錦町'},
    {position: [36.302670, 136.314682], contents: '石川県加賀市'},
    {position: [36.561050, 136.656631], contents: '石川県宝達志水町'},
    {position: [36.893600, 136.778992], contents: '石川県羽咋市'},
    {position: [36.446888, 136.554031], contents: '石川県能美市'},
    {position: [35.320633, 139.099731], contents: '神奈川県南足柄市'},
    {position: [35.264694, 139.152359], contents: '神奈川県小田原市'},
    {position: [35.335503, 139.349411], contents: '神奈川県平塚市'},
    {position: [35.571377, 139.373260], contents: '神奈川県相模原市'},
    {position: [35.374802, 139.219940], contents: '神奈川県秦野市'},
    {position: [35.232300, 139.106888], contents: '神奈川県箱根町'},
    {position: [35.437160, 139.426346], contents: '神奈川県綾瀬市'},
    {position: [35.333878, 139.404709], contents: '神奈川県茅ヶ崎市'},
    {position: [35.272026, 139.586273], contents: '神奈川県葉山町'},
    {position: [36.211361, 136.229019], contents: '福井県あわら市'},
    {position: [35.645222, 136.055542], contents: '福井県敦賀市'},
    {position: [33.347305, 130.754929], contents: '福岡県うきは市'},
    {position: [33.152435, 130.474731], contents: '福岡県みやこ町'},
    {position: [33.646725, 130.499908], contents: '福岡県久山町'},
    {position: [33.211952, 130.557907], contents: '福岡県八女市'},
    {position: [33.612164, 130.853745], contents: '福岡県大任町'},
    {position: [33.030247, 130.446060], contents: '福岡県大牟田市'},
    {position: [33.805447, 130.540649], contents: '福岡県宗像市'},
    {position: [33.396450, 130.555542], contents: '福岡県小郡市'},
    {position: [33.423412, 130.665573], contents: '福岡県朝倉市'},
    {position: [33.744183, 130.729645], contents: '福岡県直方市'},
    {position: [33.656147, 131.056046], contents: '福岡県築上町'},
    {position: [33.557449, 130.195496], contents: '福岡県糸島市'},
    {position: [33.499596, 130.422195], contents: '福岡県那珂川市'},
    {position: [33.792110, 130.674011], contents: '福岡県鞍手町'},
    {position: [33.646084, 130.691422], contents: '福岡県飯塚市'},
    {position: [37.819096, 140.562897], contents: '福島県伊達市'},
    {position: [37.561459, 139.821732], contents: '福島県会津坂下町'},
    {position: [37.255577, 140.247162], contents: '福島県天栄村'},
    {position: [37.029865, 140.379593], contents: '福島県棚倉町'},
    {position: [37.126377, 140.210922], contents: '福島県白河市'},
    {position: [37.201221, 140.338486], contents: '福島県矢吹町'},
    {position: [39.719929, 140.102509], contents: '秋田県三種町'},
    {position: [40.271374, 140.564362], contents: '秋田県大館市'},
    {position: [39.886723, 139.847504], contents: '秋田県男鹿市'},
    {position: [36.394817, 139.281082], contents: '群馬県みどり市'},
    {position: [36.212471, 138.789078], contents: '群馬県下仁田町'},
    {position: [36.389412, 139.063492], contents: '群馬県前橋市'},
    {position: [36.291126, 139.375366], contents: '群馬県太田市'},
    {position: [36.326298, 138.887238], contents: '群馬県安中市'},
    {position: [36.259853, 138.889954], contents: '群馬県富岡市'},
    {position: [36.531281, 138.881027], contents: '群馬県東吾妻町'},
    {position: [36.222954, 139.610275], contents: '群馬県板倉町'},
    {position: [36.405209, 139.330643], contents: '群馬県桐生市'},
    {position: [36.438648, 138.967117], contents: '群馬県榛東村'},
    {position: [36.646076, 139.044067], contents: '群馬県沼田市'},
    {position: [36.243008, 138.921753], contents: '群馬県甘楽町'},
    {position: [36.258511, 139.074570], contents: '群馬県藤岡市'},
    {position: [36.552433, 138.637573], contents: '群馬県長野原町'},
    {position: [36.321949, 139.003357], contents: '群馬県高崎市'},
    {position: [36.151814, 140.237000], contents: '茨城県かすみがうら市'},
    {position: [35.962910, 140.037064], contents: '茨城県つくばみらい市'},
    {position: [36.181633, 139.891144], contents: '茨城県八千代町'},
    {position: [35.911472, 140.050308], contents: '茨城県取手市'},
    {position: [36.178226, 139.755371], contents: '茨城県古河市'},
    {position: [36.048363, 139.888687], contents: '茨城県坂東市'},
    {position: [36.536594, 140.331711], contents: '茨城県城里町'},
    {position: [36.313354, 140.574890], contents: '茨城県大洗町'},
    {position: [36.239285, 140.352585], contents: '茨城県小美玉市'},
    {position: [36.023563, 139.993927], contents: '茨城県常総市'},
    {position: [36.542591, 140.411011], contents: '茨城県常陸大宮市'},
    {position: [36.538227, 140.530930], contents: '茨城県常陸太田市'},
    {position: [36.327274, 140.090485], contents: '茨城県桜川市'},
    {position: [36.365860, 140.471222], contents: '茨城県水戸市'},
    {position: [35.979397, 140.149536], contents: '茨城県牛久市'},
    {position: [36.190842, 140.287247], contents: '茨城県石岡市'},
    {position: [36.345127, 140.304230], contents: '茨城県笠間市'},
    {position: [36.307114, 139.983063], contents: '茨城県筑西市'},
    {position: [36.004555, 140.301910], contents: '茨城県美浦村'},
    {position: [35.990166, 140.489105], contents: '茨城県行方市'},
    {position: [36.457424, 140.486725], contents: '茨城県那珂市'},
    {position: [36.030869, 140.214767], contents: '茨城県阿見町'},
    {position: [35.911594, 140.182266], contents: '茨城県龍ケ崎市'},
    {position: [32.695541, 128.840820], contents: '長崎県五島市'},
    {position: [32.899960, 129.958221], contents: '長崎県大村市'},
    {position: [33.037029, 129.917145], contents: '長崎県東彼杵町'},
    {position: [32.750313, 129.877899], contents: '長崎県長崎市'},
    {position: [32.835213, 130.187515], contents: '長崎県雲仙市'},
    {position: [36.401943, 138.249069], contents: '長野県上田市'},
    {position: [36.248787, 138.476837], contents: '長野県佐久穂町'},
    {position: [36.806377, 138.206985], contents: '長野県信濃町'},
    {position: [35.914654, 138.240738], contents: '長野県富士見町'},
    {position: [36.326874, 138.426025], contents: '長野県小諸市'},
    {position: [35.842457, 137.691528], contents: '長野県木曽町'},
    {position: [36.272087, 138.315994], contents: '長野県立科町'},
    {position: [36.348309, 138.596970], contents: '長野県軽井沢町'},
    {position: [36.851746, 138.365494], contents: '長野県飯綱町'},
    {position: [35.551540, 137.878525], contents: '長野県高森町'},
    {position: [40.926022, 140.955887], contents: '青森県平内町'},
    {position: [40.584114, 140.566513], contents: '青森県平川市'},
    {position: [40.602966, 140.464005], contents: '青森県弘前市'},
    {position: [34.965679, 139.102051], contents: '静岡県伊東市'},
    {position: [35.027714, 138.928879], contents: '静岡県伊豆の国市'},
    {position: [34.976589, 138.946716], contents: '静岡県伊豆市'},
    {position: [35.088936, 138.953354], contents: '静岡県函南町'},
    {position: [34.651089, 138.858536], contents: '静岡県南伊豆町'},
    {position: [35.360111, 138.987289], contents: '静岡県小山町'},
    {position: [34.836418, 138.176056], contents: '静岡県島田市'},
    {position: [34.637978, 138.128113], contents: '静岡県御前崎市'},
    {position: [35.308617, 138.934509], contents: '静岡県御殿場市'},
    {position: [34.768719, 137.998398], contents: '静岡県掛川市'},
    {position: [34.835602, 137.927094], contents: '静岡県森町'},
    {position: [35.095722, 138.863342], contents: '静岡県沼津市'},
    {position: [34.710865, 137.726120], contents: '静岡県浜松市'},
    {position: [34.718475, 137.531616], contents: '静岡県湖西市'},
    {position: [35.095985, 139.071548], contents: '静岡県熱海市'},
    {position: [34.717880, 137.851517], contents: '静岡県磐田市'},
    {position: [34.757721, 138.084534], contents: '静岡県菊川市'},
    {position: [34.867348, 138.257553], contents: '静岡県藤枝市'},
    {position: [34.750160, 137.924622], contents: '静岡県袋井市'},
    {position: [35.173889, 138.906723], contents: '静岡県裾野市'},
    {position: [34.325211, 134.171982], contents: '香川県さぬき市'},
    {position: [34.342793, 134.046570], contents: '香川県まんのう町'},
    {position: [34.268356, 134.134369], contents: '香川県三木町'},
    {position: [34.182556, 133.715210], contents: '香川県三豊市'},
    {position: [34.316479, 133.860504], contents: '香川県坂出市'},
    {position: [33.174580, 132.960281], contents: '高知県四万十町'},
    {position: [33.534832, 133.373337], contents: '高知県日高村'},
    {position: [33.564125, 133.700546], contents: '高知県香南市'},
    {position: [33.558788, 133.531158], contents: '高知県黒潮町'},
    {position: [31.596788, 130.557343], contents: '鹿児島県さつま町'},
    {position: [28.377274, 129.493774], contents: '鹿児島県奄美市'},
    {position: [31.813486, 130.303955], contents: '鹿児島県薩摩川内市'},
    {position: [31.596788, 130.557343], contents: '鹿児島県鹿児島市'},
    {position: [31.378267, 130.852219], contents: '鹿児島県鹿屋市'}
  ]

  const keysoft = [
    {position: [35.170538, 139.110847], contents: '湯河原CC'},
    {position: [35.361931, 139.139374], contents: 'チェックメイトCC'},
    {position: [35.393767, 139.151837], contents: '太平洋C相模C'},
    {position: [35.379499, 139.144026], contents: '小田原GC松田C'},
    {position: [35.585243, 139.236278], contents: '津久井湖GC'},
    {position: [35.586084, 139.142288], contents: '神奈川CC'},
    {position: [35.353215, 139.206676], contents: 'レインボーCC'},
    {position: [35.344393, 138.888257], contents: '富士平原GC'},
    {position: [35.285104, 138.854597], contents: '太平洋C御殿場ウエスト'},
    {position: [35.23587,  138.934039], contents: '小田原GC日動御殿場C'},
    {position: [35.1878,   138.856954], contents: '三島GC'},
    {position: [34.791967, 138.118352], contents: 'ホロンGC'},
    {position: [34.781147, 138.104739], contents: '菊川CC'},
    {position: [35.252859, 139.968016], contents: '鹿野山GC'},
    {position: [35.245026, 139.988375], contents: 'ジャパンPGAGC'},
    {position: [35.266638, 140.012269], contents: 'ゴールド木更津CC'},
    {position: [36.425733, 138.605222], contents: '太平洋C軽井沢R'},
    {position: [36.454712, 138.601661], contents: '軽井沢高原GC'},
    {position: [36.349123, 138.903048], contents: 'THE CLUB golf village'},
    {position: [36.131927, 139.907782], contents: 'フレンドシップCC'},
    {position: [36.465929, 140.25536], contents: '城里GC'},
    {position: [36.486266, 140.290028], contents: 'ウインザーパークG&CC'},
    {position: [36.503779, 139.775192], contents: '鹿沼CC'},
    {position: [36.421401, 139.708609], contents: '栃木ケ丘GC'},
    {position: [36.298392, 139.650808], contents: '東武藤が丘CC'},
    {position: [36.465343, 139.721426], contents: 'トムソンCC'},
    {position: [36.386629, 140.04496], contents: 'ゴールデンレイクスCC'},
    {position: [43.280892, 141.448554], contents: '太平洋C札幌C'},
    {position: [37.540634, 140.322464], contents: '大玉CC'},
    {position: [35.127598, 136.595203], contents: '東建多度CC'},
    {position: [35.454547, 137.109804], contents: 'サンクラシックGC'},
    {position: [34.368378, 135.310259], contents: '泉南CC'},
    {position: [35.036952, 136.094396], contents: '竜王Gコース'},
    {position: [33.691334, 135.407754], contents: '南紀白浜GC'},
    {position: [34.960461, 134.728905], contents: '福崎東洋GC'},
    {position: [35.094706, 135.383188], contents: '鳳鳴CC'},
    {position: [34.479413, 133.470574], contents: 'JFE瀬戸内海GC'},
    {position: [34.340711, 134.248355], contents: '志度CC'},
    {position: [33.539372, 133.792714], contents: 'Kochi黒潮CC'},
    {position: [33.43243,  130.268447], contents: '北山CC'},
  ]

  const meimon = [
    {position: [41.796513, 140.809586], contents: '函館ゴルフ倶楽部'},
    {position: [40.536358, 141.491257], contents: '八戸ゴルフ倶楽部'},
    {position: [39.541706, 141.099335], contents: '岩手ゴルフ倶楽部'},
    {position: [38.332272, 141.013565], contents: '仙塩ゴルフ倶楽部 浦霞コース'},
    {position: [39.721127, 140.078720], contents: '秋田カントリー倶楽部'},
    {position: [38.174347, 140.314850], contents: '蔵王カントリークラブ'},
    {position: [37.710358, 140.479202], contents: '福島ゴルフ倶楽部 民報コース'},
    {position: [37.106594, 139.992386], contents: '那須ゴルフ倶楽部'},
    {position: [36.498146, 138.938751], contents: '伊香保カントリークラブ'},
    {position: [35.896309, 139.405548], contents: '東京ゴルフ倶楽部'},
    {position: [35.866077, 140.054367], contents: '我孫子ゴルフ倶楽部'},
    {position: [35.714218, 139.500412], contents: '小金井カントリー倶楽部'},
    {position: [35.266621, 138.991531], contents: '富士屋ホテル 仙石ゴルフコース'},
    {position: [37.760044, 138.844177], contents: '新潟ゴルフ倶楽部'},
    {position: [36.661102, 137.111557], contents: '呉羽カントリークラブ'},
    {position: [36.374660, 136.376205], contents: '片山津ｺﾞﾙﾌ倶楽部'},
    {position: [36.285488, 136.237091], contents: '芦原ゴルフクラブ'},
    {position: [35.408016, 138.852570], contents: '富士ゴルフコース'},
    {position: [35.432098, 136.868454], contents: '岐阜カンツリー倶楽部'},
    {position: [34.945965, 139.132004], contents: '川奈ホテルゴルフコース 大島ｺｰｽ'},
    {position: [35.110172, 137.030716], contents: '名古屋ゴルフ倶楽部'},
    {position: [35.005871, 136.030167], contents: '琵琶湖カントリー倶楽部'},
    {position: [35.066345, 135.754211], contents: '京都ゴルフ倶楽部 上賀茂コース'},
    {position: [34.823315, 135.543533], contents: '茨木カンツリー倶楽部'},
    {position: [34.758347, 135.241043], contents: '神戸ゴルフ倶楽部'},
    {position: [34.683033, 135.757095], contents: '奈良国際ゴルフ倶楽部'},
    {position: [33.664791, 135.337265], contents: '白浜ゴルフ倶楽部'},
    {position: [35.461521, 133.322937], contents: '米子ゴルフ場'},
    {position: [34.556301, 133.703415], contents: '岡山霞橋ゴルフ倶楽部'},
    {position: [34.383778, 132.376877], contents: '広島ゴルフ倶楽部 鈴が峰コース'},
    {position: [34.063667, 131.587402], contents: '毛利庭園ゴルフ倶楽部'},
    {position: [34.091801, 134.539383], contents: '徳島ゴルフ倶楽部 吉野川コース'},
    {position: [34.296436, 133.888123], contents: '高松カントリー倶楽部'},
    {position: [33.800968, 132.922760], contents: '松山ゴルフ倶楽部'},
    {position: [33.599323, 133.552795], contents: '高知ゴルフ倶楽部'},
    {position: [33.725086, 130.454056], contents: '古賀ゴルフ・クラブ'},
    {position: [33.418770, 129.934937], contents: '唐津ゴルフ倶楽部'},
    {position: [32.744427, 130.267563], contents: '雲仙ゴルフ場'},
    {position: [32.869850, 131.000519], contents: 'くまもと阿蘇ｶﾝﾄﾘｰｸﾗﾌﾞ 湯の谷ｺｰｽ'},
    {position: [33.394810, 131.425293], contents: '別府ゴルフ倶楽部'},
    {position: [31.862745, 131.445435], contents: '宮崎カントリークラブ'},
    {position: [31.872252, 130.799026], contents: '霧島ゴルフクラブ'},
    {position: [26.215101, 127.747589], contents: '沖縄カントリークラブ'}
  ]

  return (
    <MapContainer center={position} zoom={zoom}>
      <LayersControl position="topright">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.jp/{z}/{x}/{y}.png"
          maxZoom={13}
          minZoom={6}
        />
        <LayersControl.Overlay name="チョイスゴルフ場利用チケット">
          <LayerGroup>
            {maps.map((map, index) => (
              <Maker key={index} position={map.position} contents={map.contents}></Maker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="ふるさと納税自販機">
          <LayerGroup>
            {keysoft.map((map, index) => (
              <RedMaker key={index} position={map.position} contents={map.contents}></RedMaker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
        <LayersControl.Overlay name="名門ゴルフ場">
          <LayerGroup>
            {meimon.map((map, index) => (
              <YellowMaker key={index} position={map.position} contents={map.contents}></YellowMaker>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>
      </LayersControl>
    </MapContainer>
  )
};
import React from 'react';
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
L.Icon.Default.imagePath = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/'

export const YellowMaker = (props) => {

  const redIcon = L.icon({
    iconUrl: "https://esm.sh/leaflet@1.9.2/dist/images/marker-icon.png",
    iconRetinaUrl: "https://esm.sh/leaflet@1.9.2/dist/images/marker-icon-2x.png",
    shadowUrl: "https://esm.sh/leaflet@1.9.2/dist/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
    className: "icon-yellow",
  });

  return (
    <>
    <Marker position={props.position} icon={redIcon} >
      <Popup>
      {props.contents}
      </Popup>
    </Marker>
    </>
  )
};